import { Text, Button, IButtonType, Note } from '@ui/components/atoms';
import { IBadgeClaimState } from '../../../badge-claim.typings';
import linkImg from '@ui/assets/images/Link.png';
import { sendGAEvent } from '@next/third-parties/google';
import { BadgeType } from '@ui/constants';

export interface IBadgeClaimSubmit {
  badgeState?: IBadgeClaimState;
  isLoading?: boolean;
  isClaimExpired?: boolean;
  badgeType?: number | null;
  hasAchieved?: boolean;
  onClick?(): void;
  openChallenge?(): void;
}

export function BadgeClaimSubmit({
  badgeState = 'default',
  isLoading,
  isClaimExpired,
  onClick,
  badgeType,
  openChallenge,
  hasAchieved,
}: IBadgeClaimSubmit) {
  const handleButtonClick = () => {
    if (onClick) {
      onClick();
    }
    sendGAEvent('event', 'collectBadgeClicked', {
      event_label: 'collectBadgeClicked',
      value: JSON.stringify({
        badgeState,
        timestamp: new Date().toISOString(),
      }),
    });
  };

  const isPrizeBadge = badgeType === BadgeType.Prize && !hasAchieved;
  const buttonParamsMap: Record<
    IBadgeClaimState,
    { text: string; type: IButtonType; onClick?: () => void; error?: string }
  > = {
    default: {
      text: isPrizeBadge
        ? 'This is a Prize badge'
        : isClaimExpired
        ? 'Badge Claim Expired'
        : 'Collect Badge',
      type: isPrizeBadge || isClaimExpired ? 'notActive' : 'primary',
      onClick: isPrizeBadge || isClaimExpired ? undefined : handleButtonClick,
    },
    'go-to-content': {
      text: isClaimExpired ? 'Badge Claim Expired' : 'Go to Content to claim',
      type: isClaimExpired ? 'notActive' : 'primary',
      onClick: isClaimExpired ? undefined : handleButtonClick,
    },
    collected: {
      text: 'Badge Collected',
      type: 'notActive',
    },
    'already-collected': {
      text: 'Already Collected',
      type: 'notActive',
      error: 'Sorry you can only collect one of these badges',
    },
    'out-of-stock': {
      text: 'Out Of Stock',
      type: 'notActive',
    },

    loading: {
      text: '',
      type: 'notActive',
    },
  };

  return (
    <>
      <div className="mt-auto">
        <div className="flex w-full flex-col items-center bg-white">
          {isPrizeBadge && (
            <div className="font-nunito items-center text-center my-2 max-w-[370px]">
              <Note type="s" className="font-nunito">
                Earn this exclusive Prize badge by completing the challenge! Ready to prove
                yourself?{' '}
              </Note>{' '}
              <br />
              <Note type="s" className="font-nunito">
                <span
                  onClick={openChallenge}
                  className="text-primary font-semibold cursor-pointer gap-2">
                  Join the Challenge!
                </span>
              </Note>
            </div>
          )}
          <Button
            isLoading={isLoading}
            className={`min-h-[60px] w-full lg:max-w-[372px] ${
              badgeState === 'out-of-stock' && 'text-black'
            }`}
            type={buttonParamsMap[badgeState].type}
            onClick={buttonParamsMap[badgeState].onClick}>
            {buttonParamsMap[badgeState]?.text === 'Go to Content to claim' && (
              <img src={linkImg.src} alt="link" className="mr-2 h-[20px] w-[20px]" />
            )}
            {buttonParamsMap[badgeState].text}
          </Button>
          {buttonParamsMap[badgeState].error && (
            <Text type="s" className={`text-error mt-2 text-center font-semibold`}>
              {buttonParamsMap[badgeState].error}
            </Text>
          )}
        </div>
      </div>
    </>
  );
}
