import { twMerge } from 'tailwind-merge';
import DefaultBannerImage from '@ui/assets/images/DefaultBanner.png';
import { LoadingSkeleton } from './loading-skeleton';

const BannerTypeSizeMap = {
  fan: 'h-40 lg:h-52 xl:h-60 rounded-t-xl',
  creator: 'h-20 rounded-t-md',
  preview: 'rounded-t-xl max-h-[160px]',
};

type IBannerType = keyof typeof BannerTypeSizeMap;

interface IBanner {
  image?: string;
  type: IBannerType;
  className?: string;
  isLoading?: boolean;
}

export function Banner({ image, type, isLoading, className }: IBanner) {
  const bgImage = image || DefaultBannerImage.src;
  const typeStyles = BannerTypeSizeMap[type];

  return isLoading ? (
    <LoadingSkeleton
      className={twMerge('h-auto max-h-[200px] min-h-[100px] w-full', typeStyles, className)}
    />
  ) : (
    <img
      className={twMerge(
        'h-auto max-h-[200px] min-h-[100px] w-full object-cover',
        typeStyles,
        className,
      )}
      src={bgImage}
    />
  );
}
