import Pattern from '@ui/assets/images/MessagePattern.png';
import { TextWithLinks } from './message-link';
import { twMerge } from 'tailwind-merge';
import { Avatar, Icon, Text, Title } from '../atoms';
import { Badge } from '../organisms';
import { getBadgeInfo } from '@ui/lib/utils';

interface IMessage {
  avatar: string;
  image: string;
  title?: string;
  message: string;
  type?: 'form' | 'message';
  wrapperClassName?: string;
  innerClassName?: string;
  messageClassName?: string;
  titleClassName?: string;
  richDescription?: string;
}

export function Message({
  avatar,
  image,
  title,
  message,
  type = 'message',
  wrapperClassName,
  innerClassName,
  messageClassName,
  titleClassName,
  richDescription,
}: React.PropsWithChildren<IMessage>) {
  const info = getBadgeInfo(richDescription ? richDescription : '');

  return (
    <div
      className={twMerge('flex h-full w-full flex-grow', wrapperClassName)}
      style={{
        background: `linear-gradient(0deg, #FFF2D6 0%, #FFF2D6 100%), url(${Pattern.src}), lightgray`,
        backgroundBlendMode: 'hard-light',
        backgroundRepeat: 'repeat',
        backgroundSize: '250px 250px',
        backgroundPosition: '0% 0%',
      }}>
      <div
        className={twMerge('flew-grow flex h-full w-full flex-col', innerClassName)}
        style={{
          background:
            'linear-gradient(135deg, rgba(255, 151, 81, 0.30) 3.65%, rgba(255, 214, 51, 0.30) 48.96%, rgba(255, 143, 45, 0.30) 100%)',
          backgroundBlendMode: 'lighten, normal',
        }}>
        {info && (
          <div className="border-note z-0 mb-8 ml-14 flex w-[80%] items-center rounded-t-[25px] border-b pb-1 md:backdrop-blur-lg">
            <Badge
              type={info?.type}
              image={info?.image}
              size="xxs"
              color={info?.color}
              className="mr-4 lg:mr-2"
            />
            <Text type="s" className="text-note">
              You recieved this message after collecting {info?.name}
            </Text>
          </div>
        )}
        <div className="flex w-full max-w-[600px] flex-col lg:w-2/3">
          {type === 'form' && (
            <Title
              type="m"
              className={twMerge(`mb-12 ${title ? 'opacity-100' : 'opacity-30'}`, titleClassName)}>
              {title || 'Title'}
            </Title>
          )}
          <div className={twMerge('flex w-full gap-2.5', messageClassName)}>
            <div className="self-end">
              <Avatar size="sm" src={avatar} />
            </div>
            <div className="w-4/5">
              {type === 'message' && !image ? null : (
                <div className="flex w-full justify-center">
                  <div
                    className={twMerge(
                      'bg-tabActive mb-2 flex h-64 items-center justify-center rounded-lg border border-white',
                      image ? 'w-fit' : 'w-full',
                    )}>
                    {image ? (
                      <img src={image} className="h-64 rounded-lg object-cover" />
                    ) : (
                      <Icon type="imagePlaceholder" />
                    )}
                  </div>
                </div>
              )}

              <div
                className={`relative rounded-lg bg-white px-6 py-4 
            ${message ? 'opacity-100' : 'opacity-50'}`}>
                <Text type="l">
                  <TextWithLinks text={message || 'Message'} useCase="messaging" />
                </Text>
                <Icon type="messageCorner" className="absolute -left-0.5 bottom-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
