import { BadgeType } from '@ui/constants';

interface IBadgeSizeItem {
  wrapperSize: string;
  circleSize: string;
  badgeSize: string;
  snSize: string;
  borderMap: Record<BadgeType, string>;
}

export const BADGE_SIZE_MAP: Record<string, IBadgeSizeItem> = {
  xl: {
    wrapperSize: 'w-80 h-80',
    circleSize: 'w-64 h-64',
    badgeSize: 'w-48 h-48',
    snSize: 'text-base',
    borderMap: {
      [BadgeType.Engagement]: 'w-72 h-72',
      [BadgeType.Prize]: 'w-72 h-72',
      [BadgeType.Purchase]: 'w-80 h-80',
    },
  },
  l: {
    wrapperSize: 'w-64 h-64',
    circleSize: 'w-52 h-52',
    badgeSize: 'w-40 h-40',
    snSize: 'text-sm',
    borderMap: {
      [BadgeType.Engagement]: 'w-56 h-56',
      [BadgeType.Prize]: 'w-56 h-56',
      [BadgeType.Purchase]: 'w-64 h-64',
    },
  },
  md: {
    wrapperSize: 'w-40 h-40',
    circleSize: 'w-[120px] h-[120px]',
    badgeSize: 'w-[100px] h-[100px]',
    snSize: 'text-sm',
    borderMap: {
      [BadgeType.Engagement]: 'w-36 h-36',
      [BadgeType.Prize]: 'w-36 h-36',
      [BadgeType.Purchase]: 'w-44 h-44',
    },
  },
  xs: {
    wrapperSize: 'w-20 h-20',
    circleSize: 'w-[72px] h-[72px]',
    badgeSize: 'w-14 h-14',
    snSize: 'hidden',
    borderMap: {
      [BadgeType.Engagement]: 'w-20 h-20',
      [BadgeType.Prize]: 'w-20 h-20',
      [BadgeType.Purchase]: 'w-24 h-24',
    },
  },
  xxs: {
    wrapperSize: 'w-16 h-16',
    circleSize: 'w-10 h-10',
    badgeSize: 'w-8 h-8',
    snSize: 'hidden',
    borderMap: {
      [BadgeType.Engagement]: 'w-12 h-12',
      [BadgeType.Prize]: 'w-12 h-12',
      [BadgeType.Purchase]: 'w-14 h-14',
    },
  },
  xxxs: {
    wrapperSize: 'w-9 h-9',
    circleSize: 'w-7 h-7',
    badgeSize: 'w-5 h-5',
    snSize: 'hidden',
    borderMap: {
      [BadgeType.Engagement]: 'w-8 h-9',
      [BadgeType.Prize]: 'w-8 h-9',
      [BadgeType.Purchase]: 'w-10 h-10',
    },
  },
};
