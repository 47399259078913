'use client';

interface NotificationModalProps {
  closeModal: () => void;
}
export function InAppNotificationBanner({ closeModal }: NotificationModalProps) {
  return (
    <div className="fixed inset-0 z-50 flex items-start justify-end bg-black bg-opacity-50">
      <div className="relative mr-4 mt-5 w-80 rounded-lg bg-white p-6 shadow-lg">
        <div
          className="bg-primary absolute right-[-15px] top-[-15px] rotate-45 transform"
          style={{ width: '20px', height: '20px' }}>
          <div
            className="absolute right-1 top-1 rotate-[-45deg] transform bg-white"
            style={{ width: '12px', height: '12px' }}></div>
        </div>
        <p className="font-nunito mb-4 text-center font-medium">
          For a better experience and Google sign-in support, please use the menu at the top right
          to open this page in your default browser..
        </p>
        <button
          onClick={closeModal}
          className="bg-primary mt-4 w-full rounded-full p-2 font-semibold text-white">
          OK
        </button>
      </div>
    </div>
  );
}
