import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatBadgeNumber(num: number, total: number) {
  if ((!num && num !== 0) || !total) {
    return;
  }

  const length = total.toString().length;

  const digits = num.toString();
  const paddingLength = length - digits.length;

  if (paddingLength > 0) {
    const padding = '0'.repeat(paddingLength);
    return `${padding}${digits}`;
  } else if (paddingLength === 0) {
    return digits;
  } else {
    if (digits.length < 3) {
      return digits;
    }

    const reversedDigits = digits.split('').reverse();

    const formattedDigits = reversedDigits.reduce((acc, digit, index) => {
      return index > 0 && index % 3 === 0 ? `${digit},${acc}` : `${digit}${acc}`;
    }, '');

    return formattedDigits;
  }
}

export const getBadgeInfo = (info: string | undefined) => {
  if (!info) return null;
  try {
    const parsed = JSON.parse(info);
    if (typeof parsed === 'object' && parsed !== null && 'color' in parsed) {
      return parsed;
    }
    if (typeof parsed === 'object' && parsed !== null && 'badgeInfo' in parsed) {
      return JSON.parse(parsed.badgeInfo);
    }
  } catch (error) {}
  return null;
};

export const detectInAppBrowser = (): boolean => {
  const userAgent = navigator.userAgent;
  const inAppBrowser =
    /(wv|webView|ig|Instagram|FB_IAB|FB4A|FBAN|Facebook|Twitter|TikTok|LinkedIn|Reddit|Snapchat|WhatsApp|WeChat|Line|Pinterest|Viber)/gi;
  return inAppBrowser.test(userAgent);
};

export const abbreviateNumber = (num: number): string => {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + 'B';
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + 'M';
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + 'K';
  }
  return num?.toString();
};

export const sanitizeFilename = (filename: string) => {
  // Replace spaces with underscores
  let sanitizedFilename = filename.replace(/\s+/g, '_');

  // Remove all characters except alphanumeric, underscores, dots, and hyphens
  sanitizedFilename = sanitizedFilename.replace(/[^a-zA-Z0-9._-]/g, '');

  return sanitizedFilename;
};
