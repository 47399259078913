import { Text } from '@ui/components/atoms';
import { Badge } from '../badge';
import { SourceItem } from '@ui/components/moleculas';
import { BadgeSource, BadgeType } from '@ui/constants';
import { Button } from '@ui/components/ui/button';

export interface IFanProfileBadgeItem {
  type: BadgeType;
  image: string;
  color: string;
  name: string;
  badgeNumber: number;
  badgeAmount: number;
  platform: BadgeSource;
  onClick?: VoidFunction;
}

export function FanProfileBadgeItem({
  type,
  image,
  color,
  name,
  badgeAmount,
  badgeNumber,
  platform,
  onClick,
}: IFanProfileBadgeItem) {
  return (
    <div className="bg-input flex max-w-xs flex-col items-center gap-2 overflow-x-hidden rounded-xl p-6 lg:rounded-none lg:bg-transparent lg:p-0">
      <Button
        className="hover:bg-secondaryHover flex h-24 w-fit items-center bg-transparent p-2 shadow-none"
        onClick={onClick}>
        <Badge
          type={type}
          image={image}
          color={color}
          size={'xs'}
          serialNumber={badgeNumber}
          badgeAmount={badgeAmount}
        />
      </Button>
      <div className="flex h-8 items-center lg:h-10">
        <Text
          type="s"
          className="line-clamp-1 max-w-[90px] truncate whitespace-pre-wrap text-center font-semibold lg:line-clamp-2">
          {name}
        </Text>
      </div>

      <Text type="s" className="lg:bg-input rounded-xl bg-white px-2 py-1 ">
        {badgeNumber} / {badgeAmount}
      </Text>
      <div className="bg-input hidden rounded-xl px-2 py-1 lg:block">
        <SourceItem source={platform} iconClassName="w-4 h-4" className="text-sm" />
      </div>
    </div>
  );
}
