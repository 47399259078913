'use client';

import { useState } from 'react';

import { Button, Icon } from '@ui/components/atoms';

import { Input, IInput } from './input';

interface IInputCopy extends IInput {}

const COPY_BUTTON_TIMER = 3000;

export function InputCopy(props: IInputCopy) {
  const [isCopied, setCopied] = useState(false);

  function onCopy() {
    setCopied(true);
    navigator.clipboard.writeText(props.value || '');
    setTimeout(() => setCopied(false), COPY_BUTTON_TIMER);
  }

  return (
    <div className="flex flex-col items-center lg:flex-row lg:items-end">
      <Input {...props} disabled={true} />
      <Button
        type={isCopied ? 'input-active' : 'input'}
        className="-mt-4 justify-center px-4 py-2 lg:my-7 lg:ml-2 lg:mt-0 lg:py-4"
        onClick={isCopied ? undefined : onCopy}>
        {isCopied ? (
          'Copied!'
        ) : (
          <>
            <Icon type="copy" className="mr-2" /> Copy
          </>
        )}
      </Button>
    </div>
  );
}
