import React, { ReactNode } from 'react';
import { EngagementMetric } from '../engagement-typings';
import {
  MessageCircleMore,
  MessageCircleReply,
  ArrowUp,
  ArrowDown,
  SmilePlus,
  MessageSquareShare,
} from 'lucide-react';
import { Text } from '@ui/components/atoms';

interface EngagementMetricCardProps {
  metric: EngagementMetric;
  title: string;
}

const EngagementMetricCard: React.FC<EngagementMetricCardProps> = ({ metric, title }) => {
  const Icon = {
    Comments: <MessageCircleMore className="w-5 h-5" />,
    'Replies Received': <MessageCircleReply className="w-5 h-5" />,
    'Replies Given': <MessageSquareShare className="w-5 h-5" />,
    'Downvotes Given': <ArrowDown className="w-5 h-5" />,
    'Downvotes Received': <ArrowDown className="w-5 h-5" />,
    'Reactions Given': <SmilePlus className="w-5 h-5" />,
    'Reactions Received': <SmilePlus className="w-5 h-5" />,
    'Upvotes Given': <ArrowUp className="w-5 h-5" />,
    'Upvotes Received': <ArrowUp className="w-5 h-5" />,
  }[title];

  return (
    <div className="border border-border rounded-lg p-4 w-full">
      <Text type="xs" className="mb-[5px] text-note text-[11px]">
        {title}
      </Text>
      <div className="w-full flex items-center justify-between">
        <Text type="xl" className="font-bold">
          {metric?.score?.toFixed(3) || "0.000"}
        </Text>
        {Icon}
      </div>
    </div>
  );
};

export default EngagementMetricCard;
