'use client';
import { useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { Button, Icon } from '@ui/components/atoms';

import { InputForm, IInputForm } from './input-form';

interface IInputEditable<T extends FieldValues> extends IInputForm<T> {
  onSave(): void;
  isLoading?: boolean;
}

export function InputEditable<T extends FieldValues>({
  onSave: onSubmit,
  isLoading,
  ...props
}: IInputEditable<T>) {
  const [isEditable, setEditable] = useState(false);

  function onEdit() {
    if (props.disabled) {
      return;
    }
    setEditable(true);
  }

  async function onSave() {
    await onSubmit();
    setEditable(false);
  }

  return (
    <div className="flex">
      <InputForm {...props} disabled={isLoading || !isEditable || props.disabled} />
      <Button
        isLoading={isLoading}
        disabled={props.disabled}
        type={isEditable ? 'input-active' : 'input'}
        className="my-7 ml-2 w-32 justify-center"
        onClick={isEditable ? onSave : onEdit}>
        {isEditable ? (
          'Save'
        ) : (
          <>
            <Icon type="edit" className="mr-2" /> Edit
          </>
        )}
      </Button>
    </div>
  );
}
