import { twMerge } from 'tailwind-merge';
import {
  Button,
  Avatar,
  Icon,
  LoadingSkeleton,
  Text,
  FormImage,
  MobileHeader,
} from '@ui/components/atoms';
import { BadgeImageInfo } from '@ui/components/moleculas/badge-image-info';
import { IBadgeClaimState, IBadgeClaimType } from '../badge-claim.typings';
import { badgeClaimClassName } from '../badge-claim.style';
import { MobileHeaderBackButton } from '@ui/components/moleculas';
import { BadgeType } from '@ui/constants';

interface IBadgeClaimImage {
  avatar?: string;
  showAvatar?: boolean;
  name: string;
  image: string;
  caption: string;
  type: BadgeType;
  color: string;
  claimType: IBadgeClaimType;
  badgeState: IBadgeClaimState;
  badgeNumber: number;
  badgeAmount?: number;
  perks: string;
  onBack?(): void;
  gotoForum?(): void;
  creatorHasCommunity?: boolean;
  onBadgeLeaderboard?(): void;
  badgeType?: number | null;
}

export function BadgeClaimImage({
  name,
  avatar,
  showAvatar,
  caption,
  image,
  type,
  color,
  claimType,
  badgeState = 'default',
  badgeNumber,
  perks,
  badgeAmount,
  onBack,
  onBadgeLeaderboard,
  gotoForum,
  badgeType,
  creatorHasCommunity,
}: IBadgeClaimImage) {
  const textParamMap: Record<string, { text: string }> = {
    default: { text: 'Collect Badge to Unlock Perks' },
    collected: { text: 'Badge Perks' },
    'out-of-stock': { text: 'Collect Badge to Unlock Perks' },
    'already-collected': {
      text: 'Badge Perks',
    },
  };

  const className = badgeClaimClassName[claimType];
  return (
    <FormImage
      className={className.container.image}
      internalClassName={className.container.imageInternal}>
      <MobileHeader className={className.badgeImage.header}>
        <MobileHeaderBackButton onBack={onBack} />
        <Icon type="fanLogoBlack" width={110} height={30} />
        <Avatar size="sm" src={avatar} className={showAvatar ? '' : 'invisible'} />
      </MobileHeader>
      {badgeState === 'loading' ? (
        <div className="flex h-full flex-col items-center justify-center py-4">
          <LoadingSkeleton className="mb-6 aspect-square w-48 lg:w-64" />
          <LoadingSkeleton className="mb-4 h-12 w-64" />
          <LoadingSkeleton className="h-10 w-60" />
        </div>
      ) : (
        <div
          className={twMerge(
            'flex h-full flex-1 flex-col items-center justify-center',
            className.badgeImage.container,
          )}>
          <div
            className={`${
              (badgeState === 'go-to-content' ||
                badgeState === 'out-of-stock' ||
                (badgeState === 'default' && badgeType === BadgeType.Prize)) &&
              'opacity-[0.6] grayscale'
            }`}>
            <BadgeImageInfo
              name={name}
              caption={caption}
              image={image}
              type={type}
              color={color}
              badgeAmount={badgeAmount}
              size={claimType === 'default' ? 'xl' : 'l'}
              badgeNumber={badgeNumber}
            />
          </div>
          <div className={'flex w-full flex-col items-center justify-center gap-4 pb-8 lg:pb-12'}>
            <div className={twMerge('max-w-sm px-6', perks ? '' : 'hidden')}>
              <Text type="m" className="mb-2 text-center font-semibold">
                {textParamMap[badgeState]?.text}
              </Text>
              <div className="min-w-[300px] rounded-md bg-transparent bg-gradient-to-br from-[#8F00FF] to-[#FFA500] p-0.5">
                <div className="h-full w-full max-w-sm rounded-[14px] bg-white p-4">
                  <Text type="m">{perks}</Text>
                </div>
              </div>
            </div>
            <div
              className={twMerge(
                'flex w-full justify-center gap-3 lg:hidden',
                onBadgeLeaderboard ? '' : 'hidden',
                className.badgeImage.buttons,
              )}>
              <Button type="secondary" onClick={onBadgeLeaderboard}>
                <Icon type="leaderboard" className="mr-3" /> Ranking
              </Button>
              {creatorHasCommunity && (
                <Button
                  type="primary"
                  className="text-md max-w-[45vw] bg-black hover:bg-black/95"
                  onClick={gotoForum}
                  size="small">
                  <Icon type="community" className="mr-3" /> Community
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </FormImage>
  );
}
