'use client';
import { Title, Note, Text, SourceItem, Icon } from '@ui/components';
import { BadgeSource, BadgeType } from '@ui/constants';
import { twMerge } from 'tailwind-merge';

interface IBadgeClaimInfo {
  badgeNumber: number;
  badgeAmount: number;
  transferDate: string;
  platform: BadgeSource;
  publicLink: string;
  isAvailable?: string | boolean;
  currentTab?: string;
  isClaimExpired?: boolean;
  badgeType?: number | null;
}

export function BadgeClaimInfoDetails({
  badgeAmount,
  badgeNumber,
  transferDate,
  platform,
  publicLink,
  isAvailable,
  currentTab,
  isClaimExpired,
  badgeType,
}: IBadgeClaimInfo) {
  function onPublicLink() {
    if (!publicLink) {
      return;
    }
    window.open(publicLink, '_blank');
  }

  return (
    <div className="mb-4 flex w-full flex-col gap-2">
      <div className="bg-input flex flex-col items-center rounded p-3">
        <Note type="xs" className="text-[#534944]">
          {!isAvailable &&
          (currentTab === 'My Badges' ||
            currentTab === 'All Badges' ||
            currentTab === 'Leaderboards')
            ? 'Badges Remaining'
            : 'Badge Number'}
        </Note>
        <Title type="mm">
          #
          {!isAvailable &&
          (currentTab === 'My Badges' ||
            currentTab === 'All Badges' ||
            currentTab === 'Leaderboards')
            ? 0
            : badgeNumber}
        </Title>
        <div className="bg-inputActive rounded-full px-2 py-1">
          <Note type="m" className="text-[#534944]">
            of {badgeAmount}
          </Note>
        </div>
      </div>

      <div className="flex gap-2">
        <div className={`bg-input flex basis-1/2 flex-col items-center justify-center rounded p-3`}>
          <Note type="xs" className="text-[#534944]">
            Transfer Date
          </Note>
          <Text type="m" className="font-semibold">
            {transferDate}
          </Text>
        </div>
        <div
          onClick={isClaimExpired ? undefined : onPublicLink}
          className={twMerge(
            'bg-input flex basis-1/2 items-center justify-between gap-2 rounded p-3',
            publicLink && !isClaimExpired
              ? 'hover:bg-inputActive active:bg-secondaryHover cursor-pointer'
              : '',
          )}>
          {isClaimExpired && badgeType === BadgeType.Engagement ? (
            <div className="w-full items-center text-center">
              <Text type="m" className="font-semibold text-[#534944]">
                Expired
              </Text>
            </div>
          ) : badgeType === BadgeType.Prize ? (
            <div className="w-full flex items-center justify-center">
              <Icon type="trophy" className="items-center"></Icon>
            </div>
          ) : (
            <>
              <div className="w-6" />
              <SourceItem source={platform} />
              {publicLink ? <Icon type="link" /> : <div className="w-6" />}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
